import { toast } from "react-toastify";
import { setEmployeeLoading } from ".";
import { employee_http } from "../../https/employee_http";
import { API_URL, API_URL_V2 } from "../../utils/constants";
import * as actionTypes from "../actionTypes";

export const fetchOperationsHeadApprovalList = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/store/approval?role=operations-head`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_OPERATIONS_HEAD_APPROVAL_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateOperationsHeadApproval = (partnerID, data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/store/approval?store_id=${partnerID}`, data)
      .then(async (res) => {
        await dispatch(setEmployeeLoading(false));
        await dispatch(fetchOperationsHeadApprovalList());
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const addClient = (body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL_V2}/client`, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.ADD_CLIENT_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
        toast.success("Client Added !");
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchAllClient = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL_V2}/client`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.FETCH_ALL_CLIENTS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchAllClientEmail = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL_V2}/client-email`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.FETCH_ALL_CLIENTS_EMAIL,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const addClientEmail = (body) => {
  console.log(body);
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL_V2}/client-email`, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.FETCH_ALL_CLIENTS_EMAIL,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateClient = (data, id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL_V2}/client/${id}`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_CLIENT_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
